import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    username: localStorage.getItem("username") || "", // 可选
    mobile: localStorage.getItem("mobile") || "", // 可选
    email: localStorage.getItem("email") || "", // 可选
  },
  getters: {
    getToken(state) {
      return state.token || localStorage.getItem("token") || "";
    },
  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    delToken(state) {
      state.token = "";
      state.username = "";
      state.mobile = "";
      state.email = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("mobile");
      localStorage.removeItem("email");
    },
    // 可选
    setUserName(state, username) {
      state.username = username;
      localStorage.setItem("username", username);
    },
    setUserMobile(state, mobile) {
      state.mobile = mobile;
      localStorage.setItem("mobile", mobile);
    },
    setUserEmail(state, email) {
      state.email = email;
      localStorage.setItem("email", email);
    },
  },
  actions: {},
  modules: {},
});
