import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/localcuisine",
    name: "localcuisine",
    component: () => import("../views/localcuisine/localcuisine.vue"),
  },
  {
    path: "/gourmetdetails/:id",
    name: "gourmetdetails",
    component: () => import("../views/gourmetdetails/gourmetdetails.vue"),
  },
  {
    path: "/gushibeauty",
    name: "gushibeauty",
    component: () => import("../views/gushibeauty/gushibeauty.vue"),
  },
  {
    path: "/scenicdetails/:id",
    name: "scenicdetails",
    component: () => import("../views/scenicdetails/scenicdetails.vue"),
  },
  {
    path: "/charmgushi",
    name: "charmgushi",
    component: () => import("../views/charmgushi/charmgushi.vue"),
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    component: () => import("../views/newsdetail/newsdetail.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news/news.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search/search.vue"),
  },
  {
    path: "/videodetails/:id",
    name: "videodetails",
    component: () => import("../views/videodetails/videodetails.vue"),
  },
  {
    path: "/broadcast/:id",
    name: "broadcast",
    component: () => import("../views/broadcast/broadcast.vue"),
  },
  {
    path: "/broadcastdetail/:id",
    name: "broadcastdetail",
    component: () => import("../views/broadcastdetail/broadcastdetail.vue"),
  },
  {
    path: "/radio",
    name: "radio",
    component: () => import("../views/radio/radio.vue"),
  },
  {
    path: "/newstv/:id/:pid",
    name: "newstv",
    component: () => import("../views/newstv/newstv.vue"),
  },
  {
    path: "/searchtv",
    name: "searchtv",
    component: () => import("../views/searchtv/searchtv.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/shortvideo",
    name: "shortvideo",
    component: () => import("../views/shortvideo/shortvideo.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/register.vue"),
  },
  {
    path: "/introduction/:id/:pid",
    name: "introduction",
    component: () => import("../views/introduction/introduction.vue"),
  },
  {
    path: "/gushitvdetails",
    name: "gushitvdetails",
    component: () => import("../views/gushitvdetails/gushitvdetails.vue"),
  },
  {
    path: "/hoststyle",
    name: "hoststyle",
    component: () => import("../views/hoststyle/hoststyle.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () => import("../views/password/password.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/user.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
